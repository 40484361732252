// features/loginModalSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface GalleryModalState {
  viewGalleryModal: boolean;
}

const initialState: GalleryModalState = {
  viewGalleryModal: false,
};

const galleryModalSlice = createSlice({
  name: 'galleryModal',
  initialState,
  reducers: {
    showGalleryModal: (state) => {
      state.viewGalleryModal = true;
    },
    hideGalleryModal: (state) => {
      state.viewGalleryModal = false;
    },
  },
});

export const { showGalleryModal, hideGalleryModal } = galleryModalSlice.actions;

export const viewGalleryModal = ({ galleryModal }: { galleryModal: any }) =>
  galleryModal.viewGalleryModal;

export default galleryModalSlice.reducer;
