import store, { resetStore } from '@/store';
import { apiService } from './ApiService';
import {
  resetUserDetails,
  setIsUserAuthorized,
  setUserDetails,
} from '@/store/features/userSlice';
import { setCookie, deleteCookie, getCookie } from 'cookies-next';
import { clearCart } from '@/store/features/cartSlice';
import axios from 'axios';
import { googleLogout } from '@react-oauth/google';
const AUTO_LOGOUT_DURATION = 3 * 60 * 60 * 1000;

export const userService = {
  register,
  login,
  logout,
  checkOtp,
  loginWithOtp,
  verifyOtp,
  forgotWithEmail,
  verifyForgotOtp,
  loginWithSocial,
  checkTokenExpire,
};

function register(formData: any) {
  return apiService.post(`register`, formData).then((response) => {
    return response;
  });
}

function setLoggedUserDetails(response: any) {
  localStorage.setItem('loginTime', new Date().toISOString());
  store.dispatch(setUserDetails(response.data));
  store.dispatch(setIsUserAuthorized(true));
  setCookie('isUserAuthenticated', true);
  setCookie('woodenstreetUserAuthToken', response.data.token);
  checkTokenExpire();
}

function login(formData: any) {
  return apiService.post(`login`, formData).then((response: any) => {
    if (response.code == 200) {
      setLoggedUserDetails(response);
    }
    return response;
  });
}

function loginWithOtp(formData: any) {
  return apiService.post(`loginWithOtp`, formData).then((response: any) => {
    return response;
  });
}

function forgotWithEmail(formData: any) {
  return apiService.post(`forgotPassword`, formData).then((response: any) => {
    return response;
  });
}

function logout() {
  delete axios.defaults.headers.common.Authorization;
  deleteCookie('woodenstreetUserAuthToken');
  deleteCookie('isUserAuthenticated');
  store.dispatch(clearCart());
  store.dispatch(resetUserDetails());
  store.dispatch(setIsUserAuthorized(false));
  localStorage.removeItem('loginTime');
  googleLogout();
  if (typeof window !== 'undefined') {
    (window as any).FB?.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        (window as any).FB.logout();
      }
    });
  }
  // Router.push('/');
  window.location.href = '/';
  resetStore();
}

function checkOtp(formData: any) {
  return apiService.post(`checkOtp`, formData).then((response: any) => {
    return response;
  });
}

function verifyOtp(formData: any) {
  return apiService.post(`verifyOtp`, formData).then((response: any) => {
    if (response.code == 200) {
      setLoggedUserDetails(response);
    }
    return response;
  });
}

function verifyForgotOtp(formData: any) {
  return apiService
    .post(`verifyForgotPasswordOtp`, formData)
    .then((response: any) => {
      return response;
    });
}

function loginWithSocial(formData: any) {
  return apiService.post(`socialLogin`, formData).then((response: any) => {
    if (response.code == 200) {
      setLoggedUserDetails(response);
    }
    return response;
  });
}

function checkTokenExpire() {
  const checkSession = () => {
    const loginTime = localStorage.getItem('loginTime');

    if (loginTime) {
      const elapsed = new Date().getTime() - new Date(loginTime).getTime();
      if (elapsed > AUTO_LOGOUT_DURATION) {
        userService.logout();
      }
    }
  };
  let intervalId: any = '';
  if (getCookie('isUserAuthenticated')) {
    intervalId = setInterval(checkSession, 60 * 1000);
  } else {
    clearInterval(intervalId);
  }
}
