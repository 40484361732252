import { createSlice } from '@reduxjs/toolkit';

// Define the shape of the user state
interface UserState {
  user: any;
  authorized: any;
}

// Define the initial state
const initialState: UserState = {
  user: {},
  authorized: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUserAuthorized: (state, action) => {
      state.authorized = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    resetUserDetails: (state) => {
      state.user = {};
    },
  },
  extraReducers: () => {},
});

export const { setUserDetails, resetUserDetails, setIsUserAuthorized } =
  userSlice.actions;
export const getUserDetails = ({ user }: { user: any }) => user.user;
export const getIsUserAuthorized = ({ user }: { user: any }) => user.authorized;

export default userSlice.reducer;
