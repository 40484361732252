// import { getCookie } from 'cookies-next';
// import { apiService } from '@/services/ApiService';
import { /* createAsyncThunk, */ createSlice } from '@reduxjs/toolkit';

interface WishlistState {
  items: any;
}

const initialState: WishlistState = {
  items: {},
};
// const getWishlist = createAsyncThunk('getWishlist', async () => {
//   if (!getCookie('woodenstreetUserAuthToken')) {
//     setWishlistDetails({});
//     return { code: 500 };
//   }
//   const response: any = await apiService.get(
//     `getWishlistProducts`,
//     '',
//     '',
//     true
//   );
//   if (Object.keys(response?.data).length > 0) {
//     setWishlistDetails(response.data);
//   }
//   return response;
// });
// const addWishlist = createAsyncThunk('addWishlist', async (data: any) => {
//   if (!getCookie('woodenstreetUserAuthToken')) return { code: 505 };
//   const response: any = await apiService.post(`addWishlistProduct`, data, true);
//   return response;
// });
// const removeWishlist = createAsyncThunk('removeWishlist', async (data: any) => {
//   if (!getCookie('woodenstreetUserAuthToken')) return { code: 505 };
//   const response: any = await apiService.post(
//     `removeWishlistProduct`,
//     data,
//     true
//   );
//   return response;
// });
const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlistDetails: (state, action) => {
      state.items = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { setWishlistDetails } = wishlistSlice.actions;
export const getWishlistDetails = ({ wishlist }: { wishlist: any }) =>
  wishlist.items;

export default wishlistSlice.reducer;
