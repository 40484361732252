import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface StoreLocationState {
  location: any;
  storesList: any;
}

const initialState: StoreLocationState = {
  location: {},
  storesList: [],
};

export const getCityStoresList = createAsyncThunk(
  'getCityStoresList',
  async () => {
    const response = await apiService.get(`getCityStores`);
    return response;
  }
);

const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    setStoreLocationDetails: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCityStoresList.fulfilled, (state, action: any) => {
      state.storesList = action.payload?.data.cities;
    });
  },
});

export const { setStoreLocationDetails } = storeLocationSlice.actions;
export const getStoreLocationDetails = ({ stores }: { stores: any }) =>
  stores.location;
export const getAllCityStoresList = ({ stores }: { stores: any }) => {
  return stores?.storesList;
};

export default storeLocationSlice.reducer;
