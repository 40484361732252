import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCookie } from 'cookies-next';

// Define the shape of the cart state
interface OrderState {}

// Define the initial state
const initialState: OrderState = {};

export const getOrder = createAsyncThunk('orderList', async () => {
  const token = !getCookie('woodenstreetUserAuthToken');
  const response = await apiService.get(`orderList`, '', '', !token);
  return response;
});
export const addOrder = createAsyncThunk('addOrder', async (params: any) => {
  const token = !getCookie('woodenstreetUserAuthToken');
  const response = await apiService.post(`addOrder`, params, !token);
  return response;
});
export const getOrderbyid = createAsyncThunk(
  'getOrderbyid',
  async (params: any) => {
    const response = await apiService.post(`orderbyid`, params);
    return response;
  }
);
export const updateOrder = createAsyncThunk(
  'updateOrder',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    const response = await apiService.put(`updateOrder`, '', params, !token);
    return response;
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default orderSlice.reducer;
