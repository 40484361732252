import { createSlice } from '@reduxjs/toolkit';

interface MobileFilterState {
  visible: boolean;
  category: any;
}

const initialState: MobileFilterState = {
  visible: false,
  category: {},
};

const mobileFilterSlice = createSlice({
  name: 'mobileFilter',
  initialState,
  reducers: {
    showFilter: (state, action) => {
      state.visible = true;
      state.category = action.payload;
    },
    hideFilter: (state) => {
      state.visible = false;
    },
  },
});

export const { showFilter, hideFilter } = mobileFilterSlice.actions;

export default mobileFilterSlice.reducer;
