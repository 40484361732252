import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Define the shape of the cart state
interface TicketState {}

// Define the initial state
const initialState: TicketState = {};

export const createTicket = createAsyncThunk(
  'createTicket',
  async (params: any) => {
    const response = await apiService.post(`createTicket`, params, true);
    return response;
  }
);

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default ticketSlice.reducer;
