import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Define the shape of the cart state
interface FilterState {
  activeFilter: string;
  selectedFilters: any;
  filterProducts: any;
  isClearAll: boolean;
  priceRange: any;
  applyMobileFilter: boolean;
}

export const filterCategories = createAsyncThunk(
  'filterCategories',
  async (params: any) => {
    const payload = {
      pricerange: '',
      filter: '',
      brand: '',
      order: 'DESC',
      sort: 'p.best_seller',
      ...params.data,
    };
    const response = await apiService.post(
      `applyFilter?category_id=${params.id}&pageNumber=${params.page}&limit=${params.limit}`,
      payload
    );
    return response;
  }
);

// Define the initial state
const initialState: FilterState = {
  selectedFilters: {
    pricerange: '',
    filter: '',
    brand: '',
    order: 'DESC',
    sort: 'p.best_seller',
    discount: '',
  },
  filterProducts: {},
  activeFilter: 'Sort By',
  isClearAll: true,
  priceRange: {
    minValue: 0,
    maxValue: 0,
  },
  applyMobileFilter: false,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setIsClearAllFilter: (state, action) => {
      state.isClearAll = action.payload;
    },
    setPriceRange: (state, action) => {
      state.priceRange = action.payload;
    },
    setApplyMobileFilter: (state, action) => {
      state.applyMobileFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterCategories.fulfilled, (state, action: any) => {
      state.filterProducts = action.payload?.data;
    });
  },
});

export const {
  setSelectedFilters,
  setActiveFilter,
  setIsClearAllFilter,
  setPriceRange,
  setApplyMobileFilter,
} = filterSlice.actions;
export const getSelectedFilters = ({ filters }: { filters: any }) =>
  filters.selectedFilters;
export const getActiveFilter = ({ filters }: { filters: any }) =>
  filters.activeFilter;
export const getIsClearAllFilter = ({ filters }: { filters: any }) =>
  filters.isClearAll;
export const getFilterProducts = ({ filters }: { filters: any }) =>
  filters.filterProducts;
export const getPriceRange = ({ filters }: { filters: any }) =>
  filters.priceRange;
export const getApplyMobileFilter = ({ filters }: { filters: any }) =>
  filters.applyMobileFilter;

export default filterSlice.reducer;
