import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ComboState {
  checkedItems: {
    [productId: string]: {
      qty: number;
      special_price: number;
      price: number;
      flat_Off: number;
      product_id: number;
    };
  };
  totalDiscountWithCombo: number;
  totalDiscount: number;
  totalAmount: number;
  totalSpecialPrice: number;
  mainProductQuantity: number;
  comboProductQuantity: number;
}

const initialState: ComboState = {
  checkedItems: {},
  totalDiscountWithCombo: 0,
  totalDiscount: 0,
  totalAmount: 0,
  totalSpecialPrice: 0,
  mainProductQuantity: 1,
  comboProductQuantity: 1,
};

const calculateDiscounts = (state: ComboState) => {
  let totalPrice = 0;
  let totalSpecialPrice = 0;
  let totalDiscount = 0;

  Object.values(state.checkedItems).forEach((product) => {
    const { flat_Off, price, special_price, qty } = product;

    totalPrice += price * qty;
    totalSpecialPrice += (special_price - flat_Off) * qty;
    totalDiscount += (price - (special_price - flat_Off)) * qty;
  });

  const extraDiscount = totalSpecialPrice * 0.02;

  state.totalAmount = totalPrice;
  state.totalSpecialPrice = totalSpecialPrice;
  state.totalDiscount = totalDiscount;
  state.totalDiscountWithCombo = totalDiscount + extraDiscount;
};

const comboSlice = createSlice({
  name: 'combo',
  initialState,
  reducers: {
    addItemCombo(
      state,
      action: PayloadAction<{ productId: string; item: any }>
    ) {
      const { productId, item } = action.payload;
      state.checkedItems[productId] = item;

      calculateDiscounts(state);
    },
    removeItemCombo(state, action: PayloadAction<string>) {
      delete state.checkedItems[action.payload];
      calculateDiscounts(state);
    },
    updateMainProductQuantity(state, action: PayloadAction<number>) {
      const quantity = action.payload;
      state.mainProductQuantity = quantity;

      // Update quantity of main product in checkedItems
      const mainProductId = Object.keys(state.checkedItems)[0]; // Assuming there's only one main product
      if (mainProductId) {
        state.checkedItems[mainProductId].qty = quantity;
      }

      calculateDiscounts(state);
    },
    updateComboProductQuantity(state, action: PayloadAction<number>) {
      const quantity = action.payload;
      state.comboProductQuantity = quantity;

      // Update quantity of combo product in checkedItems
      const comboProductId = Object.keys(state.checkedItems)[1]; // Assuming there's only one combo product
      if (comboProductId) {
        state.checkedItems[comboProductId].qty = quantity;
      }

      calculateDiscounts(state);
    },
  },
});

export const {
  addItemCombo,
  removeItemCombo,
  updateMainProductQuantity,
  updateComboProductQuantity,
} = comboSlice.actions;
export default comboSlice.reducer;
