import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './features'; // Ensure rootReducer is correctly typed

const persistConfig = {
  debug: true,
  key: 'root',
  storage,
  whitelist: ['user' /* , 'viewColumn' */],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export function initializeStore(preloadedState: RootState) {
//   // Add _persist to the preloadedState
//   const enhancedPreloadedState = {
//     ...preloadedState,
//     _persist: {
//       version: -1, // Set version to -1 to force rehydration
//       rehydrated: false, // Set rehydrated to false initially
//     },
//   };
//   return configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware({
//         immutableCheck: false,
//         serializableCheck: false,
//       }),
//     // preloadedState: enhancedPreloadedState,
//     devTools: process.env.NODE_ENV === "development",
//   });
// }

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  // preloadedState: enhancedPreloadedState,
  devTools: process.env.NODE_ENV === 'development',
});

// let store: Store;
export const persistor: Persistor = persistStore(store);

export const resetStore = async () => {
  await persistor.purge();
  await persistor.flush();
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
