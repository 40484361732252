import store from '@/store';
import React, { useEffect, ReactNode } from 'react';
// import { useDispatch } from 'react-redux';
import { persistStore } from 'redux-persist';

interface ReduxWrapperProps {
  children: ReactNode;
}

export const ReduxWrapper: React.FC<ReduxWrapperProps> = ({ children }) => {
  // const dispatch = useDispatch();

  useEffect(() => {
    // Only load Redux Persist on the client side
    if (typeof window !== 'undefined') {
      /* eslint-disable-next-line */
      const persistor = persistStore(store);
      // Optional: You can dispatch actions here to rehydrate your store
    }
  }, []);

  return <>{children}</>;
};
