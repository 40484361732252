import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProductQuantity {
  product_id: number;
  quantity: number;
  price: number;
  special_price: number;
  flat_off: number;
  displaysetname: string;
  image: string;
  name?: string;
}

interface QuantitiesState {
  quantities: ProductQuantity[];
  totalPrice: number;
  totalDiscount: number;
  c_displaysetname: string;
  discountPercentage: string;
  totalFlatoff: number;
}

const initialState: QuantitiesState = {
  quantities: [],
  totalPrice: 0,
  totalDiscount: 0,
  c_displaysetname: '',
  discountPercentage: '0',
  totalFlatoff: 0,
};

const customSetQuantitiesSlice = createSlice({
  name: 'customSetQuantities',
  initialState,
  reducers: {
    setQuantities(state, action: PayloadAction<ProductQuantity[]>) {
      state.quantities = action.payload;
      state.c_displaysetname = calculateDisplaySetName(state);
      calculateTotals(state);
    },
    updateQuantity(
      state,
      action: PayloadAction<{ index: number; quantity: number }>
    ) {
      const { index, quantity } = action.payload;

      if (state.quantities[index]) {
        state.quantities[index].quantity = quantity;
        state.c_displaysetname = calculateDisplaySetName(state);

        calculateTotals(state);
      }
    },
  },
});

const calculateDisplaySetName = (state: QuantitiesState): string => {
  const displaySetNames = state.quantities
    .filter((product) => product.quantity > 0)
    .map((product) => {
      if (product.quantity > 0) {
        const name = product.displaysetname.split(' ')?.[0];
        if (!Number.isNaN(name)) return name;
        return parseInt(name);
      }
    });
  // console.log('displaySetNames :>> ', displaySetNames, displaySetNames.toString());
  if (displaySetNames.length < 0) return displaySetNames.toString();
  return `${displaySetNames.sort().reverse().join('+')}`;
};

const calculateTotals = (state: QuantitiesState) => {
  let totalPrice = 0;
  let totalSpPrice = 0;
  let totalFlatOff = 0;
  let totalDiscount = 0;

  state.quantities
    .filter((product) => product.quantity > 0)
    .forEach((product) => {
      totalPrice += product.price * product.quantity;
      totalSpPrice += product.special_price * product.quantity;
      totalFlatOff += product.flat_off * product.quantity;
      totalDiscount +=
        product.price * product.quantity -
        (product.special_price * product.quantity -
          product.flat_off * product.quantity);
    });

  // Calculate discount percentage
  const discountPercentage = ((totalDiscount / totalPrice) * 100).toFixed(2);

  state.totalPrice = totalSpPrice;
  state.totalDiscount = totalDiscount;
  state.discountPercentage = discountPercentage;
  state.totalFlatoff = totalFlatOff;
};

export const { setQuantities, updateQuantity } =
  customSetQuantitiesSlice.actions;

export default customSetQuantitiesSlice.reducer;
