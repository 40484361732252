import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the shape of the cart state
interface GuestState {
  guestDetails: any; // Optional userId for logged-in users
  isContinued: boolean;
}

// Define the initial state
const initialState: GuestState = {
  guestDetails: {},
  isContinued: false,
};

const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    // Add item to cart
    setGuestDetails(state: GuestState, action: PayloadAction<any>) {
      state.guestDetails = action.payload;
    },
    setIsContinued(state: GuestState, action: PayloadAction<any>) {
      state.isContinued = action.payload;
    },
  },
});

export const { setGuestDetails, setIsContinued } = guestSlice.actions;

export const getGuestDetails = ({ guest }: { guest: any }) =>
  guest.guestDetails;
export const getIsContinued = ({ guest }: { guest: any }) => guest.isContinued;

export default guestSlice.reducer;
