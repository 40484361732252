import { createSlice } from '@reduxjs/toolkit';

interface MobileMenuState {
  visible: boolean;
}

const initialState: MobileMenuState = {
  visible: false,
};

const mobileMenuSlice = createSlice({
  name: 'mobileMenu',
  initialState,
  reducers: {
    showMenu: (state) => {
      state.visible = true;
    },
    hideMenu: (state) => {
      state.visible = false;
    },
  },
});

export const { showMenu, hideMenu } = mobileMenuSlice.actions;

export default mobileMenuSlice.reducer;
