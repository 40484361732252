import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCookie } from 'cookies-next';

// Define the shape of the cart state
interface CommonState {
  requestedSettings: any;
  priceDetails: any;
}

// Define the initial state
const initialState: CommonState = {
  requestedSettings: {},
  priceDetails: {},
};

export const fetchStateAndCity = createAsyncThunk(
  'fetchStateAndCity',
  async (pincode: any) => {
    const payload = {
      pincode: pincode,
    };
    const response = await apiService.post(`getPincodeDetails`, payload);
    return response;
  }
);

export const fetchRequestedSettings = createAsyncThunk(
  'fetchRequestedSettings',
  async (params: string[]) => {
    const payload = {
      fieldValues: params,
    };
    const response = await apiService.post(
      `getRequestedSettingsValue`,
      payload
    );
    return response;
  }
);

export const fetchAllProducts = createAsyncThunk(
  'fetchAllProducts',
  async (params: any) => {
    const response = await apiService.post(`getAllProducts`, params, true);
    return response;
  }
);
export const getKitchenProduct = createAsyncThunk(
  'getKitchenProduct',
  async (params: any) => {
    const response = await apiService.get(`getKitchenProduct/${params}`);
    return response;
  }
);

export const isUserAlreadyPresent = createAsyncThunk(
  'isUserAlreadyPresent',
  async (params: any) => {
    const queryParams = new URLSearchParams({ identifier: params }).toString();
    const response = await apiService.get(
      `isUserAlreadyPresent?${queryParams}`
    );
    return response;
  }
);
export const checkPincode = createAsyncThunk(
  'checkPincode',
  async (params: any) => {
    const response = await apiService.post(`pinCheck`, params);
    return response;
  }
);
export const addFeedback = createAsyncThunk(
  'addFeedback',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    const response = await apiService.post(`addFeedback`, params, !token);
    return response;
  }
);
export const getWallet = createAsyncThunk('getWallet', async (page: number) => {
  const response = await apiService.get(
    `getWalletTransactions?page=${page}&limit=10`,
    '',
    '',
    true
  );
  return response;
});
export const getCustomerHelp = createAsyncThunk('getCustomerHelp', async () => {
  const response = await apiService.get(`getCustomerHelp`);
  return response;
});
export const getSearchProducts = createAsyncThunk(
  'getSearchProducts',
  async (params: any) => {
    const response = await apiService.post(`getSearchProducts`, params);
    return response;
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRequestedSettings.fulfilled, (state, action: any) => {
      state.requestedSettings = action.payload?.data;
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, action: any) => {
      state.priceDetails = action.payload?.data?.data;
    });
  },
});

export const getRequestedSettings = ({ common }: { common: any }) =>
  common.requestedSettings;
export const getAllProducts = ({ common }: { common: any }) =>
  common.priceDetails;

export default commonSlice.reducer;
