import { createSlice } from '@reduxjs/toolkit';

interface CustomerState {
  detail: any;
  address: any;
}

const initialState: CustomerState = {
  detail: {},
  address: {},
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      state.detail = action.payload;
    },
    setCustomerAddress: (state, action) => {
      state.address = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { setCustomerDetails, setCustomerAddress } = customerSlice.actions;
export const getCustomerDetails = ({ customer }: { customer: any }) =>
  customer.detail;
export const getCustomerAddress = ({ customer }: { customer: any }) =>
  customer.address;

export default customerSlice.reducer;
